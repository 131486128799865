<template>
  <article class="control">
    <label :for="labelName" class="label" :class="{ active: isActive }">
      {{ placeholder }}
    </label>
    <label class="is-fullwidth">
      <input
        :name="labelName"
        class="input"
        :class="{ 'is-danger': error }"
        @focus="setActive"
        @blur="setBlur"
        :value="value"
        @input="$emit('update', $event.target.value)"
      />
    </label>
  </article>
</template>

<script>
export default {
  name: 'InputField',
  props: ['labelName', 'placeholder', 'value', 'error'],
  model: {
    prop: 'value',
    event: 'update',
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    setActive() {
      this.isActive = true;
    },
    setBlur() {
      this.isActive = this.$props.value.length !== 0;
    },
  },
};
</script>

<style scoped lang="scss">
input {
  &.input {
    width: 100%;
    background-color: $grey;
    border-radius: 0;
    padding: 1.5rem 0.8rem;
    color: #fff;
    font-size: 0.8rem;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $primary;
    }
  }
}

.control {
  position: relative;
  display: flex;
  align-items: center;
}

label {
  &.is-fullwidth {
    width: 100%;
  }
  &.label {
    z-index: 1;
    position: absolute;
    margin-left: 0.8rem;
    margin-bottom: 0;
    font-size: 0.75rem;
    font-weight: normal;
    color: $grey-light;
  }

  &.active {
    display: none;
  }
}
</style>
